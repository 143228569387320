<template>
    
    <v-card variant="outlined">
        <v-card-title 
            class="bg-grey-lighten-3 pa-1 d-flex align-center"
        >
            <v-spacer></v-spacer>
            
            <div class="d-flex">
                <v-icon color="black" class="mr-1">mdi-ev-station</v-icon>
                <span> {{ chargepoint.reference_name }} </span>
                <v-btn variant="text" size="x-small" icon :to="`/chargepoint/${chargepoint.id}`">
                    <v-icon size="x-large">mdi-arrow-top-right</v-icon>
                </v-btn>
            </div>
            
            <v-spacer></v-spacer>
            
        </v-card-title>
        <v-divider></v-divider>

        <!-- Status -->
        <div v-if="chargepoint.deleted_at" class="error d-flex justify-center text-overline">
            <span class="font-weight-bold">Removed - {{ dateFullFormat(chargepoint.deleted_at) }}</span>
        </div>
        
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{chargepoint.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{ chargepoint.backend_id || '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Core Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" :sm="parentColumnSize == 6 ? 2 : 3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{chargepoint.number}}</v-list-item-title>
                            <v-list-item-subtitle>Number</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" :sm="parentColumnSize == 6 ? 4 : 3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{chargepoint.updated_at}}</v-list-item-title>
                            <v-list-item-subtitle>Updated</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
            </v-row>
            
            <v-row v-if="chargepoint.evses.length > 0">
                <v-col cols="12" sm="6" :md="columnSize.parent"
                    v-for="evse in chargepoint.evses" :key="evse.id"
                >
                    <EvseStructureCard :evse="evse" :parentColumnSize="columnSize" :mode="mode"/>
                </v-col>
            </v-row>

            <span v-else class="d-flex justify-center text-overline">No evses found</span>

        </v-card-text>
    </v-card>

</template>

<script>
    import EvseStructureCard from "@/components/evse/EvseStructureCard.vue";

    export default {
        name: "chargepointStructureCard",
        components: {
            EvseStructureCard
        },
        props: ["chargepoint", "parentColumnSize", "mode"],
        computed: {
            columnSize() {
                return {
                    parent: this.chargepoint?.evses?.length > 1 ? 6 : 12,
                    grandParent: this.parentColumnSize
                }
            },
        },
    }
</script>